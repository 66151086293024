import React from 'react';
import { basePageWrap, BasePageProps } from '../BasePage';
import Hero from '@components/Hero';
import Streamfields from '@streamfields/Streamfields';
import { Streamfield, WagtailImage } from '@/types';
import BannerImage from '@components/BannerImage';
import FakeSearch, { FakeSearchProps } from '@components/Fakesearch';

type Props = {
  title: string;
  subtitle?: string;
  banner_image?: WagtailImage;
  content: Streamfield[];
  subheader: string | null;
  fakesearch_data: FakeSearchProps;
} & BasePageProps;

const STWWHomePage = (props: Props) => {
  const { title = '', subtitle, banner_image, subheader } = props;

  return (
    <div className="home-page">
      {banner_image ? <BannerImage image={banner_image} /> : null}
      <Hero title={title} subtitle={subheader} text={subtitle} />
      {props.fakesearch_data ? <FakeSearch {...props.fakesearch_data} /> : null}
      {props.content ? <Streamfields fields={props.content} /> : null}
    </div>
  );
};

export default basePageWrap<Props>(STWWHomePage);
